<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        Detail Cetak Emas
        <div class="card-header-actions">
          <a href="gold-reedems" class="card-header-action" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="3">
            <h6>Transaction Date</h6>
            <div class="mb-4"><strong>{{ this.time }}</strong></div>
            <h6>Nomor Invoice</h6>
            <div class="mb-4"><strong>{{ this.invoice_number }}</strong></div>
            <h6>User</h6>
            <div class="mb-4"><strong>{{ this.user_name }}</strong></div>
            <h6>Status Transaksi</h6>
            <div class="mb-4"><strong>{{ this.status }}</strong></div>
            <h6>Metode Pembayaran</h6>
            <div class="mb-4"><strong>{{ this.method }}</strong></div>
            <h6>Biaya Admin</h6>
            <div class="mb-4"><strong>{{ this.admin_fee}}</strong></div>
          </b-col>
          <b-col sm="3">
            <h6>Nama Penerima</h6>
            <div class="mb-4"><strong>{{ this.name }}</strong></div>
            <h6>Nomor Penerima</h6>
            <div class="mb-4"><strong>{{ this.phone }}</strong></div>
            <h6>Alamat Lengkap Penerima</h6>
            <div class="mb-4"><strong>{{ this.address }}</strong></div>
            <h6>Kecamatan</h6>
            <div class="mb-4" v-if="this.district !== null"><strong>{{ this.district }}</strong></div>
            <div class="mb-4" v-else><strong>-</strong></div>
            <h6>Kelurahan</h6>
            <div class="mb-4" v-if="this.village !== null"><strong>{{ this.village }}</strong></div>
            <div class="mb-4" v-else><strong>-</strong></div>
            <h6>Kode Pos</h6>
            <div class="mb-4" v-if="this.postal_code !== null"><strong>{{ this.postal_code }}</strong></div>
            <div class="mb-4" v-else><strong>-</strong></div>
          </b-col>
          <b-col sm="3">
            <h6>Kota</h6>
            <div class="mb-4" v-if="this.city !== null"><strong>{{this.city}}</strong></div>
            <div class="mb-4" v-else><strong>-</strong></div>
            <h6>Provinsi</h6>
            <div class="mb-4" v-if="this.province !== null"><strong>{{this.province}}</strong></div>
            <div class="mb-4" v-else><strong>-</strong></div>
            <h6>Total Biaya Pengiriman</h6>
            <div class="mb-4"><strong>{{this.shipping_cost}}</strong></div>
            <h6>Total Biaya Cetak</h6>
            <div class="mb-4"><strong>{{ this.minting_fee | currency }}</strong></div>
            <h6>PPh 22</h6>
            <div class="mb-4"><strong>{{ this.tax }}</strong></div>
            <h6>Nilai Kekurangan Emas (IDR)</h6>
            <div v-if="this.insufficient !== null" class="mb-4"><strong>{{ this.insufficient }}</strong></div>
            <div v-else class="mb-4"><strong>-</strong></div>
          </b-col>
          <b-col sm="3">
            <h6>Nilai Kekurangan Emas (Gram)</h6>
            <div class="mb-4"><strong>{{ this.insufficient_gram }}</strong></div>
            <h6>Total Pembayaran</h6>
            <div class="mb-3"><strong>{{ this.total }}</strong></div>
          </b-col>
        </b-row>
        <div class="table-responsive-sm mb-5">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="left">Minting Partner</th>
                <th class="center">Nomor Invoice</th>
                <th class="center">Quantity</th>
                <th>Total Gram</th>
                <th class="left">Shipping</th>
                <th class="right">Shipping Status</th>
                <th class="right">AWB/Resi</th>
                <th class="center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(redeem, index) in redeemOrder" :key="index">
                <td class="left">{{ redeem.partner }}</td>
                <td class="center">{{ redeem.invoice_number }}</td>
                <td class="center">{{ parseInt(redeem.pcs) + " Pcs"}}</td>
                <td class="left">
                    <strong>{{ redeem.total_gram + " Gram" }}</strong>
                </td>
                <td class="left">{{ redeem.shipping_provider }}</td>
                <td class="right">{{ redeem.status }}</td>
                <td class="right">{{ redeem.detail_shipping.awb_no ? redeem.detail_shipping.awb_no : "-" }}</td>
                <td>
                  <div class="custom-actions center" >
                    <button class="btn btn-success mb-1 mr-2" @click="sendShipment(redeem)" v-show="redeem.shipment_request === true">
                      Request Pickup
                    </button>
                    <button class="btn btn-warning mb-1 mr-2" v-show="redeem.download_receipt === true" @click="printReceipt(redeem)">
                      Cetak Resi
                    </button>
                    <button class="btn btn-primary mb-1 mr-2" @click="onAction('view-item', redeem)">
                      Detail
                    </button>
                    <button class="btn mb-1" style="background-color: #4933F3; color: #FFFFFF;" v-bind:style="style" @click="onAction('edit-item', redeem)">
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <b-modal v-if="this.modal !== false" size="lg" v-model="modal" :title="`Detail Order & Shipping - ${this.detail.partner}`" @ok="closeModal" ok-only>
            <b-row class="mb-3">
              <b-col>
                <h5><strong>Detail Order</strong></h5>
                <br>
                <div v-for="(item, index) in this.detail.detail_order" :key="index">
                  <h6 style="margin: 0;"><strong>{{ item.name }}</strong></h6>
                  <div class="mb-3" style="color: #606060;">subtotal: {{ item.subtotal }}</div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5 style="margin: 0;"><strong>Detail Shipping</strong></h5>
                <br>
                <div style="color: #606060;">Shipping Method</div>
                <h6 class="mb-3"><strong>{{ this.detail.detail_shipping.method }}</strong></h6>
                <div style="color: #606060;">Shipping Status</div>
                <h6 class="mb-3"><strong>{{ this.detail.detail_shipping.status }}</strong></h6>
                <div style="color: #606060;">Shipping Fee</div>
                <h6 class="mb-3"><strong>{{ this.detail.detail_shipping.fee }}</strong></h6>
              </b-col>
            </b-row>
          </b-modal>
        </div>
        <!-- <b-row>
          <b-col>
            <h6>Mining Partner note to Customer :</h6>
            <div class="well">{{ this.note }}</div>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col>
            <b-button variant="success" v-if="this.status === 'Sedang diproses'" @click="sendShipment">Send Shipment</b-button>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col>
            <b-button class="btn btn-danger" v-show="this.status === 'Sedang diproses'">Cancel Invoice</b-button>
          </b-col>
        </b-row> -->
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
    name: 'invoice',
    data() {
        return {
            note: '',
            total: '',
            total_gram: "",
            price: "",
            tax: "",
            time: '',
            minting_fee: '',
            status: '',
            quantity: '',
            product: '',
            payment_status: "",
            address: '',
            hashed_id: '',
            minting_partner_name: '',
            shipping_cost: '',
            shipping_provider: '',
            receiver_name: '',
            phone: '',
            name: '',
            email: '',
            user_name: '',
            method: "",
            admin_fee: '',
            note_to_customer: "",
            invoice_number: '',
            weight: "",
            minting_fee_per_item: '',
            redeemOrder: [],
            increment: 1,
            province: '',
            city: '',
            district: '',
            village: '',
            insufficient: '',
            insufficient_gram: '',
            postal_code: '',
            isLoading: false,
            errors: {
              code: '',
              status: '',
              message: ''
            },
            detail: {},
            modal: false
        }
    },
    created() {

        this.$http.get(`redeem-detail/` + this.$route.params.id + `?type=` + this.$route.params.type)
        .then((result) => {
            this.hashed_id = result.data.redeem.hashed_id;
            this.time = result.data.redeem.created_at;
            this.minting_fee = result.data.redeem.minting_fee;
            this.address = result.data.redeem.shipping_address;
            this.user_name = result.data.user_excerpt;
            this.name = result.data.redeem.name;
            this.phone = result.data.redeem.phone;
            this.district = result.data.redeem.district;
            this.shipping_provider = result.data.redeem.shipping_provider
            this.village = result.data.redeem.village;
            this.postal_code = result.data.redeem.postal_code;
            this.admin_fee = result.data.redeem.admin_fee;
            this.tax = result.data.redeem.tax;
            this.status = result.data.redeem.status;
            this.shipping_cost = result.data.redeem.shipping_fee;
            this.note = result.data.redeem.minting_partner_note;
            this.redeemOrder = result.data.redeemOrder;
            this.total = result.data.redeem.final_total;
            this.method = result.data.redeem.payment_name;
            this.insufficient = result.data.redeem.insufficient;
            this.province = result.data.redeem.province;
            this.city = result.data.redeem.city;
            this.invoice_number = result.data.redeem.invoice_number;
            this.insufficient_gram = result.data.redeem.insufficient_gram;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
    },
    methods: {
      // sendShipment () {
      //   this.isLoading = true
      //   this.$http.post(`redeem-send-shipment/${this.$route.params.id}`).then((res) => {
      //     this.isLoading = false
      //     this.$swal.fire("Success!", res.data.data, "success").then(() => {
      //       location.reload();
      //     });
      //   }).catch((err) => {
      //     this.isLoading = false
      //     this.$swal.fire("Failed!", err.response.data.meta.message, "error").then(() => {
      //       location.reload();
      //     });
      //   })
      // },
      sendShipment (data) {
        this.isLoading = true
        this.$http.post(`redeem-send-shipment`, {
          transaction_id: data.transaction_id,
          type: data.type
        }).then((res) => {
          this.isLoading = false
          this.$swal.fire("Success!", res.data.data, "success").then(() => {
            location.reload()
          })
        }).catch((err) => {
          this.isLoading = false
          this.$swal.fire("Failed!", err.response.data.meta.message, "error").then(() => {
            location.reload()
          })
        })
      },
      printReceipt (data){
        this.isLoading = true
        this.$http.get(`redeem-download-receipt/` + data.transaction_id + '?type=' + data.type).then((res) => {
          const data = res.data.data.url
          location.href = data
          this.isLoading = false
          this.$swal.fire("Success!", res.data.data, "success").then(() => {
            location.reload()
          })
        }).catch((err) => {
          this.isLoading = false
          this.$swal.fire("Failed!", err.response.data.meta.message, "error").then(() => {
            location.reload()
          })
        })
      },
      onAction (action, data) {
        if (action == "view-item") {
          this.openModal(data)
        }else{
          this.$router.push({ name: 'Edit Cetak Emas', params: { id: data.transaction_id, type: data.type } })
        }
      },
      openModal (data) {
        this.modal = true
        this.detail = data
      },
      closeModal () {
        this.detail = []
        this.modal = false
      },
      formatedRupiah(number){
        if (number != null) {
          var reverse = number.toString().split('').reverse().join(''),
          thousand = reverse.match(/\d{1,3}/g)
          thousand = thousand.join('.').split('').reverse().join('')
          return 'Rp. ' + thousand
        }else{
          return 'Rp. ' + 0
        }
      }
    }
}
</script>

<style>
  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
